.add-product-order {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 800px;

    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;

    padding: 2rem;
    box-sizing: border-box;
    z-index: 999;
}

.add-product-order .title {
    font-size: 24px;
    font-weight: bold;
}

.add-product-order .option {
    box-shadow: none;
}

.add-product-order input[type="number"] {
    border: 1px solid var(--a-border-color);
    padding: .35rem .5rem .35rem .7rem;
    border-radius: 5px;

    width: 50px;
}

.add-product-order table img {
    width: 70px;
    height: 70px;
    border-radius: 5px;
}

@media screen and (max-width: 767px) {
    .add-product-order{
        padding: 1rem;
    }
    .add-product-order .table {
        max-width: 100%;
        box-sizing: border-box;
        margin-right: 0 !important;
    }
}