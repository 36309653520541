:root {
  --a-p-color: #5D5FEF;
  --a-light-p-color: #EBF2FD;

  --a-blk-color: #283C4C;
  --a-bg-color: #F5F5FA;
  --a-hover-bg-color: #F9F9FD;
  --a-border-color: #E9EDF4;

  --a-green-color: #34C38F;
  --a-light-green-color: #D1FAE6;

  --a-orange-color: #F7B84B;
  --a-light-orange-color: #FCF1DE;

  --a-gray-color: #324B76;
  --a-light-gray-color: #DDDFE1;


  --a-red-color: #E11D48;
  --a-light-red-color: #FFE4E6;

  --border-color: #EDEDED;
  --p-color: #EE2424;
  --blk-color: #212121;
  --light-gray-color: #EDEDED;
  --gray-color: #787878;
  --red-color: #EE2424;
}

* {
  font-family: 'Noto Sans Thai', sans-serif;
}

html {
  /* scroll-behavior: smooth; */
}

body {
  margin: 0;
  font-family: 'Noto Sans Thai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

a {
  color: unset;
  text-decoration: none;
}

input,
textarea,
button,
select,
a,
li,
ul,
div {
  -webkit-tap-highlight-color: transparent;
}

p {
  margin: 0;
}

ul,
li {
  list-style: none;
  padding: 0;
}

input:focus {
  outline: none;
}

input:disabled,
textarea:disabled {
  background-color: #f5f5f5 !important;
  color: #d9d9d9;
  cursor: no-drop;
}

.d-flex {
  display: flex;
}

.f-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.al-center {
  align-items: center;
}

.al-start {
  align-items: flex-start;
}

.al-end {
  align-items: flex-end;
}

.jc-center {
  justify-content: center;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.d-grid {
  display: grid;
}

.g-1 {
  grid-template-columns: 1fr;
}

.g-2 {
  grid-template-columns: repeat(2, 1fr);
}

.g-3 {
  grid-template-columns: repeat(3, 1fr);
}

.g-4 {
  grid-template-columns: repeat(4, 1fr);
}

.gap-25px {
  gap: 25px;
}

.gap-1rem {
  gap: 1rem;
}

.gap-small {
  gap: .5rem;
}

.border-round {
  border-radius: 50%;
  overflow: hidden;
}

.border-round-5px {
  border-radius: 5px;
  overflow: hidden;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.pd-d5rem-1rem {
  padding: .5rem 1rem;
}

.p-small {
  padding: .5rem;
}

.pd-b-small {
  padding-bottom: .5rem;
}

.pd-b-2rem {
  padding-bottom: 2rem;
}

.p-1rem {
  padding: 1rem;
}

.p-2rem {
  padding: 2rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.ml-0 {
  margin-left: 0 !important;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-small {
  margin-left: .5rem;
}

.mr-1 {
  margin-right: 1rem;
}

@media screen and (max-width: 767px) {
  .md-f-column {
    flex-direction: column;
  }

  .md-f-rev-column {
    flex-direction: column-reverse;
  }

  .md-w-100 {
    width: 100% !important;
  }

  .md-w-auto {
    width: auto;
  }

  .md-g-1 {
    grid-template-columns: 1fr;
  }

  .md-g-2 {
    grid-template-columns: 1fr 1fr;
  }

  .md-mt-0 {
    margin-top: 0 !important;
  }

  .md-hide {
    display: none;
  }

  .md-gap-0 {
    gap: 0;
  }

  .md-gap-small {
    gap: .5rem;
  }

  .md-mb-0 {
    margin-bottom: 0 !important;
  }
}