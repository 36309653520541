.paginationUser ul {
    flex-wrap: wrap;
}

.paginationUser li {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    color: #fff;
    background-color: var(--red-color);
    width: 35px;
    height: 35px;
    border-radius: 5px;

    cursor: pointer;
}

.paginationUser li.activePage {
    color: #fff;
    background-color: var(--p-color);
    opacity: 0.5;
    cursor: auto;
}

@media screen and (max-width: 767px) {
    .paginationUser {
        display: flex;
        justify-content: center;
    }
}